import { ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPostPageMetadata } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';

interface FetchMetadataParams {
  flowAPI: ViewerScriptFlowAPI;
  postSlug?: string;
}

interface MetadataResponse {
  viewCount: number;
  likeCount: number;
  totalComments: number;
  averageRating: number;
  totalRatings: number;
}

export const fetchMetadata = async ({
  flowAPI,
  postSlug,
}: FetchMetadataParams): Promise<MetadataResponse> => {
  if (!postSlug) {
    throw new Error('No post id provided');
  }

  const {
    data: { postPageMetadata },
  } = await flowAPI.httpClient.request(
    getPostPageMetadata({ postId: postSlug }),
  );

  return postPageMetadata?.postMetadata as MetadataResponse;
};
